$color-black: #000;
$color-white: #fff;
$color-grey: #9b9fa3;
$color-grey-light: #bcbaba;

$color-dark: #1B1B1B;
$color-dark-light: #313131;

$color-dark-blue: #0A0A0A;

$color-red: #CB0000;

