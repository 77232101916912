


@font-face {
  font-family: 'Poppins';
  src: url('../../../public/replient/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../../public/replient/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 700;
}


@font-face {
  font-family: 'Poppins';
  src: url('../../../public/replient/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 600;
}



@font-face {
  font-family: 'Poppins';
  src: url('../../../public/replient/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../../public/replient/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 300;
}




@include sm {
  .preloader-intro {
    font-size: 12vw;
  }



  .flexnav {
    .link {
      margin-bottom: 1em;
    }
  }



}
