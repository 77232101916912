// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;
$max-w-screen-lg: 991px;
// Large tablets and desktops
$screen-xl: 1200px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin max-w-lg {
  @media (max-width: #{$max-w-screen-lg}) {
    @content;
  }
}

@mixin min-w-lg {
  @media (min-width: #{$max-w-screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin min-w-xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin max-w-xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

// Custom devices
@mixin minimumWidth($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}