@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@font-face {
  font-family: "Poppins";
  src: url("../../../public/replient/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../public/replient/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../public/replient/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../public/replient/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../public/replient/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 300;
}
@media (max-width: 576px) {
  .preloader-intro {
    font-size: 12vw;
  }
  .flexnav .link {
    margin-bottom: 1em;
  }
}
.hidden {
  display: none !important;
}

.z-index-top {
  z-index: 9999;
}

.text-red {
  color: #CB0000 !important;
}

.text-white {
  color: #fff !important;
}

.text-right {
  text-align: right !important;
}

.mb-10px {
  margin-bottom: 10px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-3 {
  margin-bottom: 3em;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.p-3 {
  padding: 3em !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.image-md {
  height: 125px;
}

.height-auto {
  height: auto !important;
}

@media (max-width: 576px) {
  .mb-mobile-3 {
    margin-bottom: 3em;
  }
}

@media (max-width: 576px) {
  .mb-mobile-4 {
    margin-bottom: 4em;
  }
}

@media (max-width: 576px) {
  .pb-mobile-0 {
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  .pb-mobile-1 {
    padding-bottom: 1em !important;
  }
}

@media (max-width: 576px) {
  .pb-mobile-2 {
    padding-bottom: 2em !important;
  }
}

@media (max-width: 576px) {
  .pb-mobile-3 {
    padding-bottom: 3em !important;
  }
}

@media (max-width: 576px) {
  .text-mobile-center {
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  .mobile-hidden {
    display: none !important;
  }
}

.layers {
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  overflow: visible;
}
@media (max-width: 576px) {
  .layers {
    margin-top: 0;
  }
}
.layers g {
  transform: translateX(var(--offset-x)) translateY(var(--offset-y)) translateZ(var(--offset-z));
  transition: transform 0.5s ease-out;
}
.layers g:nth-of-type(2) {
  animation-delay: 0.2s;
}
.layers g:nth-of-type(3) {
  animation-delay: 0.4s;
}
.layers g:nth-of-type(4) {
  animation-delay: 0.6s;
}
.layers text {
  font-weight: bold;
  transform: skew(-68deg, 22deg) translate(calc(49px * var(--size) / 100), calc(7px * var(--size) / 100)) scaleY(0.5);
  fill: rgba(255, 255, 255, 0.8);
}
.layers path:nth-child(2) {
  fill: rgba(0, 0, 0, 0.3);
}
.layers path:nth-child(3) {
  fill: rgba(255, 255, 255, 0.3);
}

@keyframes hover {
  0%, 100% {
    transform: translateX(var(--offset-x)) translateY(var(--offset-y)) translateZ(var(--offset-z));
  }
  50% {
    transform: translateX(var(--offset-x)) translateY(calc(var(--offset-y) + 3px)) translateZ(var(--offset-z));
  }
}
.home-bg-animation {
  position: absolute;
  height: auto;
}

@media (max-width: 576px) {
  .our-process-section h3 {
    font-size: 2.5em;
  }
  .our-process-section h3.mobile-small {
    font-size: 2em;
  }
}

@media (max-width: 576px) {
  .uppercase-text .hero-title {
    font-size: 12vw;
  }
  .homepage-info-image {
    width: 50vw;
  }
  .clients-table {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.rep-homepage-process-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  width: 65%;
}
.rep-homepage-process-list li {
  border-bottom: 1px solid #1B1B1B;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.rep-homepage-process-list li h5 {
  margin-bottom: 5px;
}
.rep-homepage-process-list li.active h5 {
  color: #CB0000;
  font-weight: 600;
}
.rep-homepage-process-list li:hover h5 {
  color: #CB0000;
}

.rep-home-subtitle {
  color: #CB0000;
}

.partners-section {
  padding: 2em 5em;
}
@media (max-width: 576px) {
  .partners-section {
    padding: 2em 1em;
  }
}
.partners-section .section-technologies-container {
  background: #1B1B1B;
  border-radius: 20px;
  padding: 3em;
  overflow: hidden;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container {
    padding: 1.75em;
  }
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container h5 {
    font-size: 0.9em;
  }
}
.partners-section .section-technologies-container h3 {
  color: #bcbaba;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container h3 {
    font-size: 1.2em;
  }
}
.partners-section .section-technologies-container p {
  color: #bcbaba;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container p {
    font-size: 0.85em;
    line-height: 1.25;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-900px);
  }
  @media (max-width: 576px) {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-480px);
    }
  }
}
.partners-section .section-technologies-container .technologies-slider-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1800px;
  animation: scroll 20s linear infinite;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container .technologies-slider-list {
    width: 960px;
  }
}
.partners-section .section-technologies-container .technologies-slider-list .technology-item {
  padding: 0 1em;
  min-width: 150px;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container .technologies-slider-list .technology-item {
    min-width: 80px;
  }
}
.partners-section .section-technologies-container .technologies-slider-list .technology-item img {
  width: auto;
  height: 50px;
}
@media (max-width: 576px) {
  .partners-section .section-technologies-container .technologies-slider-list .technology-item img {
    height: 40px;
  }
}

.projects-section #showcase-carousel-holder {
  height: 500px !important;
}
@media only screen and (max-width: 767px) {
  .projects-section #showcase-carousel-holder {
    height: 400px !important;
  }
}
@media (max-width: 576px) {
  .projects-section #showcase-carousel-holder .w-mobile-50 {
    width: 45% !important;
  }
}
@media (max-width: 576px) {
  .projects-section #showcase-carousel-holder .img-mobile-300 {
    height: 300px !important;
  }
}

@media only screen and (max-width: 767px) {
  #showcase-carousel .swiper-slide {
    height: 300px !important;
  }
}
.project-page-details-logo {
  position: absolute;
  top: 8em;
  right: 0;
}
.project-page-details-logo img {
  max-height: 50px;
}