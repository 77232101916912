@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');


.layers {
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  overflow: visible;

  @include sm {
    margin-top: 0;
  }

  g {
    transform: translateX(var(--offset-x)) translateY(var(--offset-y)) translateZ(var(--offset-z));
    transition: transform 0.5s ease-out;
    //animation: hover 2s infinite;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }

    &:nth-of-type(4) {
      animation-delay: 0.6s;
    }
  }

  text {
    font-weight: bold;
    transform: skew(-68deg, 22deg) translate(calc(49px * var(--size) / 100), calc(7px * var(--size) / 100)) scaleY(0.5);
    fill: rgba(white, 0.8);
  }

  path:nth-child(2) {
    fill: rgba(black, 0.3);
  }

  path:nth-child(3) {
    fill: rgba(white, 0.3);
  }
}

@keyframes hover {
  0%, 100% {
    transform: translateX(var(--offset-x)) translateY(var(--offset-y)) translateZ(var(--offset-z));
  }
  50% {
    transform: translateX(var(--offset-x)) translateY(calc(var(--offset-y) + 3px)) translateZ(var(--offset-z));
  }
}