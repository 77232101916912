

.home-bg-animation {
  position: absolute;
  height: auto;
}


.our-process-section {
  h3 {
    @include sm {
      font-size: 2.5em;

      &.mobile-small {
        font-size: 2em;
      }
    }
  }
}


@include sm {
  .uppercase-text .hero-title {
    font-size: 12vw;
  }

  .homepage-info-image {
    width: 50vw;
  }

  .clients-table {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


.rep-homepage-process-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  width: 65%;

  li {
    border-bottom: 1px solid $color-dark;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.3s ease;


    h5 {
      margin-bottom: 5px;
    }

    &.active {
      h5 {
        color: $color-red;
        font-weight: 600;
      }

    }

    &:hover {
      h5 {
        color: $color-red;
      }
    }
  }
}


.rep-home-subtitle {
  color: $color-red;
}


.partners-section {
  padding: 2em 5em;
  @include sm {
    padding: 2em 1em;
  }
    .section-technologies-container {
    background: $color-dark;
    border-radius: 20px;
    padding: 3em;
    overflow:hidden;

      @include sm {
        padding: 1.75em;
      }

    h5 {
      @include sm {
        font-size: 0.9em;
      }
    }


    h3 {
      color: $color-grey-light;

      @include sm {
        font-size: 1.2em;
      }
    }

    p {
      color: $color-grey-light;
      @include sm {
        font-size: 0.85em;
        line-height: 1.25;
      }
    }


    $animationSpeed: 20s;

    @keyframes scroll {
      0% { transform: translateX(0); }
      100% { transform: translateX(calc(-150px * 6))}

        @include sm {
          0% { transform: translateX(0); }
          100% { transform: translateX(calc(-80px * 6))}
        }
      }

    .technologies-slider-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(150px * 12);
      animation: scroll $animationSpeed linear infinite;
      @include sm {
          width: calc(80px * 12);
      }



      .technology-item {
        padding: 0 1em;
        min-width: 150px;

        @include sm {
            min-width: 80px;
        }

        img {
          width: auto;
          height: 50px;
          @include sm {
            height: 40px;
          }
        }
      }

    }
  }
}



.projects-section {


  #showcase-carousel-holder {
    height: 500px !important;

    @media only screen and (max-width: 767px) {
      height: 400px !important;
    }


    .w-mobile-50 {
        @include sm {
            width: 45% !important;
        }
    }

    .img-mobile-300 {
      @include sm {
        height: 300px !important;
      }
    }
  }

}


@media only screen and (max-width: 767px) {
  #showcase-carousel .swiper-slide {
    height: 300px !important;
  }
}

