
.hidden {
    display: none !important;
}

.z-index-top {
    z-index: 9999;
}

.text-red {
    color: $color-red !important;
}

.text-white {
    color: $color-white !important;
}

.text-right {
    text-align: right !important;
}

.mb-10px {
    margin-bottom: 10px;
}


.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1em !important;
}

.mb-3 {
    margin-bottom: 3em;
}

.mt-2 {
    margin-top: 2em !important;
}

.mt-3 {
    margin-top: 3em !important;
}

.mt-1 {
    margin-top: 1em !important;
}


.mt-5px {
    margin-top: 5px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.mt-20px {
    margin-top: 20px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.p-3 {
    padding: 3em !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.image-md {
    height: 125px;
}

.height-auto {
    height: auto !important;
}

.mb-mobile-3 {
    @include sm {
        margin-bottom: 3em;
    }
}

.mb-mobile-4 {
    @include sm {
        margin-bottom: 4em;
    }
}

.pb-mobile-0 {
    @include sm {
        padding-bottom: 0;
    }
}

.pb-mobile-1 {
    @include sm {
        padding-bottom: 1em !important;
    }
}

.pb-mobile-2 {
    @include sm {
        padding-bottom: 2em !important;
    }
}

.pb-mobile-3 {
    @include sm {
        padding-bottom: 3em !important;
    }
}

.text-mobile-center {
    @include sm {
        text-align: center !important;
    }
}

.mobile-hidden {
    @include sm {
        display: none !important;
    }
}