.project-page-details-logo {
  position: absolute;
  top: 8em;
  right: 0;


  img {
    max-height: 50px;
  }




}